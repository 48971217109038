<template>
    <div id="production-order-import" class="container">
        <div class="row">
            <div class="col">
                <b-card title="Прогноз на период">
                    <div class="row">
                        <div class="col">
                            <date-time-picker
                                label="С"
                                locale="ru"
                                id="startDate"
                                :format="null"
                                input-size="sm"
                                position="bottom"
                                :no-label="true"
                                :no-header="true"
                                :no-button="true"
                                :no-clear-button="true"
                                :auto-close="true"
                                :only-date="true"
                                v-model="startDate"
                                formatted="DD.MM.YYYY"
                                :min-date="moment(minDate).format('YYYY-MM-DD')"
                                :max-date="endDate ? moment(endDate).format('YYYY-MM-DD') : null"
                            />
                        </div>
                        <div class="col">
                            <date-time-picker
                                label="По"
                                locale="ru"
                                id="'date-to"
                                :format="null"
                                input-size="sm"
                                position="bottom"
                                :no-label="true"
                                :no-header="true"
                                :no-button="true"
                                :no-clear-button="true"
                                :auto-close="true"
                                :only-date="true"
                                v-model="endDate"
                                formatted="DD.MM.YYYY"
                                :min-date="startDate ? moment(startDate).format('YYYY-MM-DD') : moment(minDate).format('YYYY-MM-DD')"
                            />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-center">
                            <b-button
                                variant="primary"
                                @click="importPlanOrders"
                                :disabled="importPlanOrdersLoading || importFactOrdersLoading"
                            >
                                <i
                                    v-show="importPlanOrdersLoading"
                                    class="fas fa-spinner fa-spin"
                                    style="margin-right: 5px;"
                                />Загрузить прогноз
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col">
                <b-card title="Факт на следующую дату">
                    <div class="row">
                        <div class="col">
                            <b-alert
                                show
                                variant="secondary"
                            >Доступно только в период между 20:05 и 23:59</b-alert>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-center">
                            <b-button
                                variant="primary"
                                @click="importFactOrders"
                                :disabled="importPlanOrdersLoading || importFactOrdersLoading || importFactOrdersDisabled"
                            >
                                <i
                                    v-show="importFactOrdersLoading"
                                    class="fas fa-spinner fa-spin"
                                    style="margin-right: 5px;"
                                /> Загрузить факт
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    import DateTimePicker from "vue-ctk-date-time-picker"
    import { PRODUCTION_ORDERS_ENDPOINT } from "../../utils/endpoints"

    const UPDATE_TIME_IN_MILLISECONDS = 60 * 1000 // every minute

    export default {
        components: {
            DateTimePicker
        },
        name: "ImportDashboard",
        computed: {
            importFactOrdersDisabled() {
                if (
                    this.time &&
                    moment(this.time) > moment(this.time).set({ hour: 20, minute: 5, second: 0, millisecond: 0 }) &&
                    moment(this.time) < moment(this.time).set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
                ) {
                    return false
                }
                return true
            }
        },
        data() {
            return {
                time: null,
                minDate: moment().add(1, "days"),
                startDate: moment().add(2, "days"),
                endDate: moment().add(2, "days").add(16, "days"),
                importPlanOrdersLoading: false,
                importFactOrdersLoading: false
            }
        },
        methods: {
            moment,
            loadTime(initial = false) {
                this.$http
                    .get("v2/time")
                    .then(response => response.bodyText)
                    .then(bodyText => {
                        this.time = moment(bodyText)
                        if (initial) {
                            this.minDate = moment(this.time).add(1, "days")
                            this.startDate = moment(this.time).add(2, "days")
                            this.endDate = moment(this.time).add(2, "days").add(16, "days")
                        }
                    })
            },
            importPlanOrders() {
                if (confirm("Вы уверены, что хотите загрузить прогноз?")) {
                    this.importPlanOrdersLoading = true
                    this.$http
                        .post(
                            PRODUCTION_ORDERS_ENDPOINT + "/import",
                            {
                                type: "plan",
                                startDate: moment(this.startDate).format("YYYY-MM-DD"),
                                endDate: moment(this.endDate).format("YYYY-MM-DD")
                            },
                            {
                                params: { without_loading: true }
                            }
                        )
                        .then(response => {
                            this.$toast.success("Прогноз загружается")
                        })
                        .catch(response => {
                            this.$toast.error("Не удалось загрузить прогноз")
                        })
                        .finally(() => {
                            this.importPlanOrdersLoading = false
                        })
                }
            },
            importFactOrders() {
                if (confirm("Вы уверены, что хотите загрузить факт?")) {
                    this.importFactOrdersLoading = true
                    this.$http
                        .post(
                            PRODUCTION_ORDERS_ENDPOINT + "/import",
                            {
                                type: "fact"
                            },
                            {
                                params: { without_loading: true }
                            }
                        )
                        .then(response => {
                            this.$toast.success("Факт загружается")
                        })
                        .catch(response => {
                            if (response && response.data && response.data.errors) {
                                this.$toast.error(response.data.errors[0])
                            } else {
                                this.$toast.error("Не удалось загрузить факт")
                            }
                        })
                        .finally(() => {
                            this.importFactOrdersLoading = false
                        })
                }
            }
        },
        mounted() {
            this.loadTime(true)
            setInterval(() => {
                this.loadTime(false)
            }, UPDATE_TIME_IN_MILLISECONDS)
        }
    }
</script>

<style>
    #production-order-import div.card-body {
        padding: 20px !important;
    }

    #production-order-import div.alert.alert-secondary {
        height: 36px !important;
        padding: 7px 10px 6px !important;
        margin: 0 !important;
    }
</style>
